import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next';

// Import Swiper styles
import 'swiper/css';
import TestimonialCard from './TestimonialCard';

import GiveContentTrans from "../components/GiveContentTrans";

export default function SubscriptionTestimonials(props) {
  // return <p>List of pizzas There are {pizzas.length } pizzas</p>;
  const { languages, originalPath, i18n } = useI18next();

  const lang = i18n.language;

  return (
 
        <div className="testimonials inner-grid">
          <div className="content-block page-copy">
            <h2>
  
              <GiveContentTrans language="et">
              Liiga hea, 
              et olla&nbsp;tõsi?
      </GiveContentTrans>
      <GiveContentTrans language="en">
      Too good to be true?
      </GiveContentTrans>
            </h2>

            <p>
       
              <GiveContentTrans language="et">
              ⭐⭐⭐⭐⭐ <br></br>
              Üle 50 kliendi on hinnanud erinevaid Taneli kujundatud 
              kodulehtede, SaaS töölaudade UI/UX ja mobiilsete veebilehtede või iOS äppide projekti viie tärniga viiest. 
              Kusjuures tegemist on 100% läbipaistva ja ausa tagasisidega, mida võime igale huvilisele tõestada. 
      </GiveContentTrans>
      <GiveContentTrans language="en">
      ⭐⭐⭐⭐⭐ <br></br>
      Over 50 clients have rated various projects designed by Tanel, including websites, SaaS desktop UI/UX, mobile web pages, or iOS apps, with a five-star rating out of five. Moreover, this feedback is 100% transparent and honest, and we can prove it to anyone interested.
      </GiveContentTrans>
            </p>
 
          </div>

       
            <div className="feedback-container">

            <div className="testimonial-card">
              <p>
            Other than great design skills what impressed me the most about Tanel is his design integrity and true caring about the quality of the work he does. You can count on him to get the job done well. Thanks Tanel.</p>
                  <div className="project-details">
                  ⭐⭐⭐⭐⭐<br></br>
                  <GiveContentTrans language="et">UI/UX disain kõige ilusamaid asju jagavale veebilehele    </GiveContentTrans>
                  <GiveContentTrans language="en">UI/UX Design for a Website Sharing the Most Beautiful Things</GiveContentTrans>
                  </div>
              </div>

                      <div className="testimonial-card">
                      <p>   Tanel is world class. I really respect his skills and work ethic. He is creative and passionate in the work he does and he is very dedicated to the cause. 
                      He strives to deliver perfection and I am very happy with his work. I am going to hire him soon for our second project as he really adds value and quality to my team. Thank you Tanel, I highly recommend him.</p>
                  <div className="project-details">
                  ⭐⭐⭐⭐⭐<br></br>
                  <GiveContentTrans language="et">Kommunikatsiooni seadmete ja teenuste veebilehe disain    </GiveContentTrans>
                  <GiveContentTrans language="en">Website Design for Communication Devices and Services</GiveContentTrans>
                  </div>
              </div>

              <div className="testimonial-card">
              <p> Tanel completely surpassed my expectations and made redesigning my website seamless and easy. He was way ahead of schedule and put incredible thought into his ideas. 
              He is visually gifted and is able to communicate the reasons behind his ideas effectively. I loved working with Tanel and will use only him on any future work I have. I can't recommend him enough.      </p>            <div className="project-details">
              ⭐⭐⭐⭐⭐<br></br>
              <GiveContentTrans language="et">Dubai haridusministeeriumi veebilehe UI/UX disain    </GiveContentTrans>
              <GiveContentTrans language="en">UI/UX Design for the Dubai Ministry of Education Website</GiveContentTrans>
                  </div>
              </div>



              <div className="testimonial-card">
                   <p> "Awesome designer", "Perfect work!", "Thank you! Excellent Work.", "One of the best web designers I have worked with in a few years!", 
                   "Very skilled designer, I am very happy with the outcome of my project!", "Excellent work, again and again.", "Great job as always. Glad that we could build trust overtime on diverse projects."</p>
                <div className="project-details">
                ⭐⭐⭐⭐⭐<br></br>
                <GiveContentTrans language="et">Tagasiside erinevatele projektidele alates iOS appi disainist kuni brändinguni    </GiveContentTrans>

<GiveContentTrans language="en">Feedback on Various Projects, from iOS App Design to Branding</GiveContentTrans>
                </div>
            </div>
            </div>


         

        </div>
      
  
  );
}
