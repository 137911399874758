import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { ListTLink, CTACard, animateObjects, newContent, ListTLinkWithDefaults } from "../../components/Layout";
import SEO from "../../components/seo";
import GiveContentTrans from "../../components/GiveContentTrans";

import MagneticButton from '../../components/MagneticButton';

import SubscriptionTestimonials from '../../components/SubscriptionTestimonials';
import Icon from '../../components/Icon';


import LogoGIF from '../../assets/give-logo-animation-1080x1080-v1.gif';

import Layout from '../../components/Layout';

// markup // const IndexPage = ({ data }) => {

function PiiramatuDisainPage() { 
  return ( 
    <Layout>
  <main className="open-service-page outer-grid"> <GiveContentTrans language="et"> 
  <SEO
          title="UI/UX ja graafilise disaini tööd ühe kuumaksuga"
          description="Kõik vajalikud UI/UX ja Graafilise disaini tööd sinu startupile ühe kuumaksuga. 🚀 Vaata vabade kohtade saadavust ja telli kvaliteetne disain kiiresti."
          image="/pagecover-teenused.jpg"
        /> </GiveContentTrans> <GiveContentTrans language="en"> <SEO
          title="Subscription Design Service"
          description=" Unlimited UI/UX and Graphic Design Requests for startups with a single monthly fee (3007€). Limited availability. Get good design now!"
          image="/pagecover-en-default.jpg"
        /> </GiveContentTrans>

      <section className="hero">
        <GiveContentTrans language="et">
          <div className="service-tag-container">
          <span className="service-tag">Üks kindel kuumaks</span>
          </div>
          <h1>Disainitööd lihtsalt&nbsp;ja&nbsp;kiirelt</h1>
        </GiveContentTrans>
        <GiveContentTrans language="en">
        <div className="service-tag-container">
          <span className="service-tag">Design subscription done the&nbsp;right&nbsp;way</span>
          </div>
          <h1>Get unlimited designs easily.</h1>
        </GiveContentTrans>

        <div className="page-copy">
          <GiveContentTrans language="et">
            <p>UI/UX disaini, veebilehtede- ja graafilise disaini tellimine ei ole kunagi olnud lihtsam. Ja kiirem.
            </p><p>
              Saad kõik <strong>digitaalsed disainitööd ühe&nbsp;kuumaksega.</strong>
            </p>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <p>Trouble finding cost effective and reliable UI/UX designer or digital agency? Or are they just too slow?  
          Here's the solution. 
            </p><p>
            <strong>All UI/UX, web and graphic design, no-code development with a simple monthly&nbsp;subscription. </strong></p>
          </GiveContentTrans>
        </div>

      </section>

      <div className="subscription-hero-image">

      <div className="hero-testimonial">




      <GiveContentTrans language="et">
      <div className="testimonial-card">
        <p>
BEST designer I have ever worked with. Quality designs, on time, quick turnaround, helped meet deadlines, understood what we wanted. All around great guy.*</p>
        <div className="project-details">
        ⭐⭐⭐⭐⭐<br></br>
         <p> Kanada meditsiinitoodete edasimüüja veebileht</p><br></br>
          <span className="text-small">* Klientide tagasiside on tõlkimata ja kopeeritud täpselt nii kuidas see jäeti. Toodud tagasiside on 100% läbipaistev ja saame seda tõestada.</span>
        </div>
    </div>
          </GiveContentTrans>
          <GiveContentTrans language="en">
          <div className="testimonial-card">   <p>
BEST designer I have ever worked with. Quality designs, on time, quick turnaround, helped meet deadlines, understood what we wanted. All around great guy.*</p>
        <div className="project-details">
        ⭐⭐⭐⭐⭐<br></br>
        Canadian Medical Product Distributor Website<br></br>
          <span className="text-small">* The provided feedback here and throughout the website is 100% transparent, and we can prove it.




</span>
        </div>
    </div>
          </GiveContentTrans>


</div>



<StaticImage
    src="../../assets/UnlimitedDesignHero.png"
    alt="UI/UX design subscription"
    title="UX/UI Subscription projects"
    layout="fullWidth"
  /> 

</div>




      <section className="wyg-section inner-grid">

      <div className="content-row">
      <div className="content-col content-col-half page-copy">
          <GiveContentTrans language="et">
            <h2>Telli disainid just siis kui&nbsp;vajad</h2>
            <p>
            Disaineri palkamisega kaasnevad bürokraatia ning lisakulud. Agentuuridega pead projekte hinnastama ning siis ootama millal sinu jaoks aega on. 

            </p>
            <p>
              Give kuumaksuga piiramatu disainipaketiga saad tellida just neid graafilise disaini veebikujundus ja UI/UX tööd, mida parajasti vajad. 
              Sa ei pea muretsema palju see maksab ning pikalt ette planeerima. Keskendu kõige olulisemale: Briif > Kvaliteetne lõpptulemus.
            </p>
   
            </GiveContentTrans>

            <GiveContentTrans language="en">
            <h2>Unlimited Design Requests Whenever You&nbsp;Need</h2>
<p>Request the design work for your company, precisely when you need it. 
  No need to spend too much time for extensive planning or discussions about budgets and deadlines. Focus on what truly matters. Quality brief > Quality outcome</p>
   
            </GiveContentTrans>
      </div>
      </div>

        <div className="content-col content-col-full">
          <GiveContentTrans language="et">

            <div className="bento-container">

              <div className="bento-item bento-1">
                <div className="bento-item-description">
                  <h3>UI/UX ja veebidisain</h3>
                  <p>
                 Kasutajasõbralikud kodulehed ja maandumislehed. SaaS veebi- ja mobiiliäppide UX/UI disain.
                  </p>
                </div>
                <div className="bento-background">
                <StaticImage
            src="../../assets/ui-ux-disain-ja-veebidisain.jpg"
            alt="Saas töölaua UI/UX disain, mobiilse veebirakenduse disain ja kodulehe disain"
            layout="fullWidth"
          />
                </div>
              </div>

              <div className="bento-item bento-2">
                <div className="bento-item-description">
                  <h3>Logode kujundamine ja firmastiili&nbsp;disain</h3>
                  <p>
                  Disainime tugeva brändi identiteedi&nbsp;0&nbsp;→&nbsp;100. 
                  </p>
                </div>

                <div className="bento-background">
                <img src={LogoGIF} alt="logo disainid"></img> </div>


                
              </div>

              <div className="bento-item bento-3">
                <div className="bento-item-description">
                  <h3>Graafiline disain</h3>
                  <p>
                  Kujundame reklaame, trükised, presentatsioone ja sotsiaalmeedia&nbsp;graafikat.
                  </p>
                </div>

                <div className="bento-background">
                <StaticImage
            src="../../assets/graafiline-disain-bussipeatuse-OOH.jpg"
            alt="Graafiline Disain Out of Home reklaamlahendus"
            layout="fullWidth"
          /> </div>
              </div>

              <div className="bento-item bento-4">
                <div className="bento-item-description">
                  <div className="item-head">
                  <h3>Disainer</h3>
                  <div className="designer-row">
                  <div className="avatar">
                <StaticImage
            src="../../assets/tanel-avatar2.jpg"
            alt="UI/UX ja Graafiline disainer"
            layout="fullWidth"
          /> </div>
                  <h2>Tanel Kaarel Sepp</h2>
                  </div>

                  </div>
                  <p>
                  Erinevalt agentuuridest, kes vahendavad, pakun kindlustunnet, et minult tellitud kujundused saavad õigeaegselt tehtud. 
                  Kuulan ning arvestan teie soove ja loon lahendused mis täidavad eesmärke. 
                  </p>
                </div>
       
              </div>


              <div className="bento-item bento-5">
                <div className="bento-5-bg"></div>
                <div className="bento-item-description">

                  <div className="tasks-list">
                  <h3>Maandumislehed</h3>
                <h3>Pitch Deckid</h3>
                <h3>Presentatsioonid</h3>
                <h3>Sotsiaalmeedia</h3>
                <h3>Uudiskirjad</h3>
                  <h3>Animeeritud bännerid</h3>
                  </div>
                </div>

                <div className="bento-background">
                <StaticImage
            src="../../assets/gradient-bg.jpg"
            alt="Gradient taust"
            layout="fullWidth"
          /> </div>

              </div>


              <div className="bento-item bento-6">
                <div className="bento-item-description">
                  <h2>48h</h2>
                  <p>
                  jooksul disainikavand teile. Tavaliselt kiiremini!
                  </p>
                </div>
              </div>
              <div className="bento-item bento-7">
                <div className="bento-item-description">
                  <h3>No-Code arendus</h3>
                  <p>
                  Framer või Webflow kodulehtede disain, arendus ja haldus.
                  </p>
                </div>

                <div className="bento-background">
                <StaticImage
            src="../../assets/framer-expert.jpg"
            alt="Framer Expert"
            layout="fullWidth"
          /> </div>

              </div>
              <div className="bento-item bento-8">
                <div className="bento-item-description">
                  <h2>Disaini "know&#8209;how" ja&nbsp;strateegia</h2>
               
                </div>

                <div className="bento-background">
                <StaticImage
            src="../../assets/ui-ux-disainistrateegia-mobiiliappile.jpg"
            alt="UI/UX disainistrateegia iOS mobiiliäppile"
            layout="fullWidth"
          /> </div>
              </div>


            </div>
          </GiveContentTrans>
      <GiveContentTrans language="en">

            <div className="bento-container">

              <div className="bento-item bento-1">
                <div className="bento-item-description">
                <h3>UI/UX and Web Design</h3>
<p>We design UI/UX for SaaS dashboards, creating components user flows and views for websites and mobile apps. We craft user-friendly websites and complete landing pages.</p>
                </div>
                <div className="bento-background">
                <StaticImage
            src="../../assets/ui-ux-disain-ja-veebidisain.jpg"
            alt="SAAS dashboard UI/UX design, mobile web app and homepage design"
            layout="fullWidth"
          />
                </div>
              </div>

              <div className="bento-item bento-2">
                <div className="bento-item-description">
                <h3>Logo Design and Corporate Identity</h3>
<p>No logo? No problem! Let us design new brand identity and take your company from 0 to 100 with professional graphic design.</p>
                </div>

                <div className="bento-background">
                <img src={LogoGIF} alt="modern logo designs"></img> </div>


                
              </div>

              <div className="bento-item bento-3">
                <div className="bento-item-description">
                <h3>Graphic Design</h3>
<p>Need design for OOH ads, print, presentations, winning pitch decks and social media graphics? We can do that too.</p>
                </div>

                <div className="bento-background">
                <StaticImage
            src="../../assets/graafiline-disain-bussipeatuse-OOH.jpg"
            alt="OOH advertising"
            layout="fullWidth"
          /> </div>
              </div>

              <div className="bento-item bento-4">
                <div className="bento-item-description">
                  <div className="item-head">
                  <h3>Who is the UI/UX Designer?</h3>
                  <div className="designer-row">
                  <div className="avatar">
                <StaticImage
            src="../../assets/tanel-avatar2.jpg"
            alt="UI/UX and Graphic Designer"
            layout="fullWidth"
          /> </div>
                  <h2>Tanel Kaarel Sepp</h2>
                  </div>

                  </div>
                  <p>Unlike agencies that act as intermediaries, I work directly and provide the assurance that designs are delivered on time. If needed, I collaborate with partners to ensure that the designs meet your expectations.</p>
                </div>
       
              </div>


              <div className="bento-item bento-5">
                <div className="bento-5-bg"></div>
                <div className="bento-item-description">

                  <div className="tasks-list">
   
<h3>Landing Pages</h3>
<h3>Design Reviews</h3>
<h3>Pitch Decks</h3>
<h3>Presentations</h3>
<h3>Social Media</h3>
<h3>Newsletters</h3>
<h3>Animated Banners</h3>
                  </div>
                </div>

                <div className="bento-background">
                <StaticImage
            src="../../assets/gradient-bg.jpg"
            alt="Gradient background"
            layout="fullWidth"
          /> </div>

              </div>


              <div className="bento-item bento-6">
                <div className="bento-item-description">
                  <h2>48h</h2>
        
<p>We will send you a concept within 48 hours. Usually even faster!</p>
                </div>
              </div>
              <div className="bento-item bento-7">
                <div className="bento-item-description">
          
<h3>No-Code Development</h3>
<p>For Premium package subscribers, we also offer No-Code website development (Framer / Webflow) and updates. In addition, we provide animated <ListTLink
              to="/services/html5banners/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >HTML5 banners
            </ListTLink> and HTML for <ListTLink
              to="/services/newsletters/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >newsletters
            </ListTLink>.</p>
                </div>

                <div className="bento-background">
                <StaticImage
            src="../../assets/framer-expert.jpg"
            alt="Framer Expert"
            layout="fullWidth"
          /> </div>

              </div>
              <div className="bento-item bento-8">
                <div className="bento-item-description">
                  <h2>Design "know&#8209;how" and Strategy</h2>
               
                </div>

                <div className="bento-background">
                <StaticImage
            src="../../assets/ui-ux-disainistrateegia-mobiiliappile.jpg"
            alt="UI/UX design strategy for iOS mobile app"
            layout="fullWidth"
          /> </div>
              </div>


            </div>
          </GiveContentTrans>
       

        </div>
   
     
      </section>
      <section className="service-full-section outer-grid">

      <div className="content-row full-row">


<div className="content-col content-col-half page-copy">
<GiveContentTrans language="et">
<h2>Kuumaksupõhine disainipakett sobib</h2>

<p>Piiramatu disainipakett teile, kui vajad tihedalt UI/UX ja graafilist disaini ning no-code veebiarendust. </p>
<p className="notification small">
Soovid teha tulemusi mitte viita aega kohtumistel. Kiire asünkroonne töö. 0 kohtumist, 0 kõnet.</p> </GiveContentTrans>
<GiveContentTrans language="en">
<h2>For Who Is the Monthly Subscription Design Package Meant?</h2>
<p>Give´s unlimited design package is ideal for you if you need continuous or seasonal assistance with various UI/UX and graphic design tasks, as well as no-code web development.</p>
</GiveContentTrans>


</div>


</div>

      <div className="for-who-container">

  

       
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <h3>Startupid</h3>
    
              <p>
              Vajavad UI/UX disaini, veebidisaini, pitch-decke, logo ning brändi materjalide kujundusi. 
On keeruline leida disainer, kes kujundab seda kõike kvaliteetselt ja kiiresti. Agentuuridest disainitööde sisseostmine on aeglane ja kallis. 
              </p>
              <p>
              Disaineri palkamisega ei tasu kiirustada. Piiramatut disainipaketiga, saate kaetud kõik vajalikud disainitööd. 
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
            <h3>Startups, Seed, A, B, C ...</h3>

            <p>
  Need UI/UX design, web design, pitch decks, logos, and branding materials. It's challenging to find a designer who can create all of this quickly and with quality. Outsourcing design work to agencies is slow and expensive.
</p>
<p>
  Don't rush into hiring a designer. With our unlimited design package, all your design needs are covered.
</p>
            </GiveContentTrans>
          </div>
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <h3>Keskmise suurusega ettevõtted</h3>
              <p>
              Püsiv vajadus erinevate disainitööde järgi. Täisajaga disaineri palkamine ei ole mõistlik. Tugev bränd, hea kodulehe kasutusmugavus ja disain annavad konkurentsitihedas turuolukorras teile eelise.</p>
              <p>
              Disainipaketiga saate tellida kujundusi täpselt siis kui teil on vaja ja olla konkurentidest alati sammuke ees.
              </p>

            </GiveContentTrans>
            <GiveContentTrans language="en">
            <h3>Medium-Sized Companies</h3>
            <p>
  Constant need for various design tasks. Hiring a full-time designer is not practical. A strong brand, good website usability, and design give you an edge in a competitive market.
</p>
<p>
  With our design package, you can order designs exactly when you need them and always stay one step ahead of your competitors.
</p>

            </GiveContentTrans>
          </div>

          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <h3>Reklaami- ja digiagentuurid</h3>
              <p>
              Kui tööd on palju ei ole mõistlik kohe oma meeskonda kasvatada. Püsikulud kasvavad. Või peate disainerile lubama puhkust. Reklaamiagentuur saab abi keerukamate veebiprojektidega.</p>
              <p>
              Give kuumaksupõhine disainipaketiga aitab kuni kiire on möödas või olete kindlad, et meeskonna laiendamine on õige samm.
              </p>

            </GiveContentTrans>
            <GiveContentTrans language="en">
            <h3>Advertising and Digital Agencies</h3>
            <p>
  When there's a lot of work, it's not wise to immediately grow your team. Overhead costs increase, and you need to allow designers to take vacations. An advertising agency can assist with more complex web projects.
</p>
<p>
  Our monthly subscription-based design package helps you manage until the busy period is over or you are sure that expanding your team is the right step.
</p>

            </GiveContentTrans>
          </div>



          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <h3>Suurettevõtted</h3>
              <p>
              Teil on majasisene disainimeeskond, lisaks koostöö ühe või mitme digi- ja reklaamiagentuuriga. 
              Teenus on teile kui SWAT (kiirreageerimisüksust).
             
              </p>
              <p>
              Saate usaldusväärse partneri, kes disainib kiirelt ja operatiivselt just siis kui seda vajate. 
              
              </p>

            </GiveContentTrans>
            <GiveContentTrans language="en">
            <h3>Large Enterprises</h3>
            <p>
  You have an in-house design team and collaborate with one or more digital and advertising agencies. Our service acts like a SWAT team for you.
</p>
<p>
  You'll get a reliable partner who designs quickly and efficiently whenever you need it.
</p>

            </GiveContentTrans>
          </div>


     
      </div>
      </section>

      <section className="description-section inner-grid page-copy">
        <GiveContentTrans language="et">
          <h2>Läbipaistev hind</h2>
          <p>Liitu kohe ja maksa arve ning saad esimese disaini juba 48h jooksul peale lähteülesande sisestamist!</p>
        </GiveContentTrans>
        <GiveContentTrans language="en">
        <h2>Simple Transparent Pricing</h2>
<p>Join now, pay the invoice, and receive your first design within 48 hours after submitting the initial task!</p>
        </GiveContentTrans>
        <div className="content-row has-media">
        <div className="content-col-half price-box">
            <GiveContentTrans language="et">

              <div className="box-header">
              <div className="service-tag">2 vaba kohta</div>
              <h2>Disain Pro</h2>
              <h3>3000€<span> +km /kuus</span></h3>
             


              

              <a href="mailto:tanel@give.ee?subject=Kuumaksup%C3%B5hise%20disainipaketti%20Pro%20tellimus&body=Ettev%C3%B5te%20v%C3%B5i%20eraisike%20kes%20soovib%20tellida%3F%20%0AValitud%20pakett%3A%20PRO%0AK%C3%BCsimusi%3F" className="heading4">
            <MagneticButton
              addionalClasses="cta"
              buttonLabel="Liitu PRO paketiga"
              magneticDistance="150"
              magneticSpeed="0.4"
            />
          </a>

          </div>
          <div className="box-content">
              <h3>Mida te saate?</h3>
              <ul>
                <li>Ligipääs 15+ aastasele disaini know-howle</li>
                <li>Piiramatult UI/UX ja graafilise disaini tellimusi</li>
        
                <li>1-2 tööpäeva tähtaeg (va. suuremahulised projektid)</li>
                <li>Üks projekt korraga töös</li>
                <li>Jätta piiramatult tagasisidet kuni olete tööga rahul</li>
                <li>Null bürokraatiat. Võid teenusest igal ajal loobuda</li>
          
                <li>Üks bränd</li>
              </ul>
              </div>
            </GiveContentTrans>
            <GiveContentTrans language="en">


            <div className="box-header">
              <div className="service-tag">2 spots available</div>
              <h2>Design Pro</h2>
              <h3>3007 €<span> +vat /month</span></h3>
             


              

              <a href="mailto:tanel@give.ee?subject=Design%20subscription%20PRO%20package&body=Who%20is%20ordering%20%0APackaget%3A%20PRO%0AQuestions%3F" className="heading4">
            <MagneticButton
              addionalClasses="cta"
              buttonLabel="Order Pro"
              magneticDistance="150"
              magneticSpeed="0.4"
            />
          </a>

          </div>
          <div className="box-content">
          <h3>What You Get</h3>
<ul>
  <li>Access to 15+ years of design know-how</li>
  <li>Unlimited UI/UX and graphic design requests</li>

  <li>1-2 business day turnaround time (except for large-scale projects)</li>
  <li>1 project in progress at a time</li>
  <li>Unlimited revisions until you are satisfied</li>
  <li>No bureaucracy. You can cancel the service anytime</li>

  <li>One brand</li>
</ul>
              </div>

            </GiveContentTrans>
          </div>

          <div className="content-col-half price-box">
            <GiveContentTrans language="et">

              <div className="box-header">
              <div className="service-tag">1 vaba koht</div>
              <h2>Disain Premium</h2>
              <h3>3507€<span> +km /kuus</span></h3>
             


              

              <a href="mailto:tanel@give.ee?subject=Kuumaksup%C3%B5hise%20disainipaketti%20Preemium%20tellimus&body=Ettev%C3%B5te%20v%C3%B5i%20eraisike%20kes%20soovib%20tellida%3F%20%0AValitud%20pakett%3A%20Preemium%0AK%C3%BCsimusi%3F" className="heading4">
            <MagneticButton
              addionalClasses="cta"
              buttonLabel="Liitu Premium paketiga"
              magneticDistance="150"
              magneticSpeed="0.4"
            />
          </a>

          </div>
          <div className="box-content">
              <h3>Mida te saate?</h3>
              <ul>
                <li>Ligipääs 15+ aastasele disaini know-howle</li>
                <li>Piiramatult UI/UX ja graafilise disaini tellimusi</li>
    
                <li>1-2 tööpäeva tähtaeg (va. suuremahulised projektid)</li>
                <li>1 projekt korraga töös</li>
                <li>Jätta piiramatult tagasisidet kuni olete tööga rahul</li>
                <li>Null bürokraatiat. Võid teenusest igal ajal loobuda</li>
       
                <li className="bullethighlight">Mitmele brändile</li>
                <li className="bullethighlight">Framer ja Webflow No-Code arendus</li>
                <li className="bullethighlight">Animeeritud HTML5 bännerid</li>
                <li className="bullethighlight">E-kirjade HTML</li>
                
              </ul>
              </div>
            </GiveContentTrans>
            <GiveContentTrans language="en">
   
            <div className="box-header">
              <div className="service-tag">1 spot available</div>
              <h2>Design Premium</h2>
              <h3>3507 €<span> +vat /month</span></h3>
             


              

              <a href="mailto:tanel@give.ee?subject=Design%20subscription%20Premium%20package&body=Who%20is%20ordering%20%0APackaget%3A%20Premium%0AQuestions%3F" className="heading4">
            <MagneticButton
              addionalClasses="cta"
              buttonLabel="Order Premium"
              magneticDistance="150"
              magneticSpeed="0.4"
            />
          </a>

          </div>
          <div className="box-content">
          <h3>What You Get</h3>
<ul>
  <li>Access to 15+ years of design know-how</li>
  <li>Unlimited UI/UX and graphic design requests</li>
  <li>1-2 business day turnaround time (except for large-scale projects)</li>
  <li>1 project in progress at a time</li>
  <li>Unlimited revisions until you are satisfied</li>
  <li>No bureaucracy. You can cancel the service anytime</li>

  <li className="bullethighlight">For multiple brands</li>
  <li className="bullethighlight">Framer and Webflow No-Code development</li>
  <li className="bullethighlight">Animated HTML5 banners</li>
  <li className="bullethighlight">HTML for email newsletters</li>
</ul>
                
              
              </div>
            </GiveContentTrans>
          </div>


     
  
        </div>

      </section>



      <SubscriptionTestimonials />

      <GiveContentTrans language="et">
        <section className="description-section inner-grid">

          <div className="section-title">
          <h3>Korduma Kippuvad Küsimused</h3><h2>kuumaksu põhise disainipaketti kohta</h2>
          </div>

        <div className="content-row has-media">
        <div id="faq">
  
  <ul>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Kui kiiresti disain valmib?</h3>
      <div className="answer">
      <p>Enamasti saadame disainid 1-2 tööpäeva jooksul, võimalusel kiiremini.</p>
      <p>Suuremad projektid võtavad kauem aega ja need jagame juppideks. Näiteks 5 alamlehelise kodulehe kujundamisel ei ole mõistlik korraga kujundada nii 5 alamlehe vaadet erinevates ekraanimõõtudes. 
       Kui tellite meilt animeeritud bannerid 10s eri mõõdus siis teeme kõigepealt näidise ühes või kahes mõõdus ja pärast kontseptsiooni kinnitamist produtseerime terve banneri paki. 
      </p>
      <p>Mida täpsemalt on lähteülesanne koostatud seda kiiremini saame pakkuda disaine.</p>
      </div>
    </li>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Kas saan esitada tõesti piiramatult disainipäringuid?</h3>
      <div className="answer">
      <p>Jah. Puuduvad piirangud mitu tööd te tellida saate. Meie eesmärk on pakkuda terviklahendust väikeettevõttele ja suuremaid ettevõtteid abistada kindlas lõigus.</p>
      <p>Projektidega tegeleme järjekorras. Ehk selleks, et alustaksime järgmise projektiga peab eelnev projekt olema kinnitatud. Igale projektile võite jätta piiramatult tagasisidet ja nii saame kindlustada selle, et olete lõpptulemusega rahul.
        </p>
  

        </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Kuidas see teenus aitab säästa raha ja aega?</h3>
      <div className="answer">
      <p>Klassikalise töömudeli puhul kui töötate digiagentuuriga hakkab kõik projekti hinnastamisest. Kui hind on kokkulepitud siis paneb digiagentuur töö kalendrisse. 
        Lähteülesande kooskõlastamine, hinnapakkumise koostamine ja ülevaatamine ning siis lepingu sõlmimine võtab kõik palju aega. Piiramatu disaini töömudeli puhul saadate te lihtsalt oma soovid ja disainer hakkab tööle. 
</p>

<p>Kui töötate digiagentuuriga tunnipõhise hinnastamise põhimõttel siis pahatihti on agentuuri eesmärk näidata tööd keerukamana ja kirjutada võimalikult palju tunde? Kohati on see loogika vastane, et kiire ja hea 
  töö eest karistatakse tegijat väiksema tasuga. Kuumaksupõhine piiramatu disainiteenus lahendab selle probleemi. Teil on vajadus kvaliteetse disaini järele ja Give soovib seda pakkuda kiiresti ja läbipaistva hinnaga. 
</p>

      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Mis juhtub, kui ma ei ole kujundusega rahul?</h3>
      <div className="answer">
      <p>Kui te ei ole kujundusega rahul saate alati anda tagasisidet ning viime sisse muudatused.</p>
      <p>Töötame projektiga seni kuni olete rahul ja loodud kujundus täidab seatud eesmärke.
        </p>
        </div>
    </li>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Kas ma saan raha tagasi, kui pole teenusega rahul?</h3>
      <div className="answer">
      <p>Ei, me ei kanna raha tagasi. Kui te pole teenusega rahul saate teenuse kasutamise igal ajal lõpetada.</p>
      <p>Kui juhtub, et me ei saa piiramatut disainiteenust osutada maksame teile antud perioodi eest raha tagasi.</p>
      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
    
      <h3>Piiramatult disainipäringuid ei ole võimalik! Kus on konks?</h3>
      <div className="answer">
      <p>Liiga hea, et olla tõsi. Aga on.</p>
      <p>Saate esitada piiramatult päringuid ja töötame nendega järjest. Ükski disainer ei suuda 10 projekti samal ajal kujundada. Ehk järgmise disainiprojektiga alustamiseks tuleb eelnev kinnitada. Ja nüüd on pall teie väravas.</p>

      <p>Mida selgemad lähteülesanded ja kiiremat tagasisidet kujundustöödele annate seda rohkem disaine jõuame teha.</p>

      <p>Piiramatu disainiteenus on optimiseeritud kiirusele. Kellele selline töövorm sobib saavutab uskumatuid tulemusi. 
        Me ei tee video ega muid kohtumisi ja ei suhtle live chatis. Detailne päring > konkreetne vastus disaini näol. Lihtne, kiire ja kasvule suunatud. Oleme sellist töömudelit graafilise disaini ja veebikujunduse puhul aastaid kasutanud ja see lihtsalt töötab.
      </p>

      <p>Piiramatu kuumaksupõhine disainiteenus on võimalik tänu kogemustega disaineri kasutamisele. Me ei planeeri, tee taustauuringuid, kujunda wireframe või teekonnakaarte. Asume kohe kujundama ja just tänu 
        kogemusele paneme üldjuhul 90% täppi. Mida täpsem ja detailsem on lähteülesanne ja vajaduste ning probleemide selgitus seda paremad tulemused tulevad. 
      </p>

      <p>Piiramatu kuupõhise disainiteenusega saab lisaks maandumislehtedele, UI/UX uuendustele, graafilisele disainile tellida ka suuremaid projekte nagu kodulehtede kujundused, SaaS töölaua UI/UX disain, mobiiliäppide disain. 
        Need projektid jagame mõistlikeks juppideks. Näide. Võtame aluseks kodulehe 7 alamlehelise kodulehe. Esimeses etappis paneme paika kodulehe esilehe kujunduse ehk üldise visuaalse stiili. Kui see on kinnitatud siis 
        järgmises etappis teeme näiteks 2 põhilist alamlehte. Kui need on kliendi poolt kinnitatud disainime puuduvad 4 kodulehe alamlehte. Peale kõikide alamlehtede kinnitamist disainime vajalikud mobiilivaated ja anname projekti üle arendajale. 
        Sellise etappiviisilise projektiga saame olla kindlad, et liigume alati õiges suunas ning klientidel on täpne ülevaade mis toimub. 
      </p>
      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Mis eelised on täiskohaga disaineri palkamise ees?</h3>
      <div className="answer">
      <p>Pakume laiemat teenuste hulka kui keskmine disainer oskab või tahab teha. Teeme kõike alatest firmastiilist, bannerist kuni keerukamate UI/UX lahendusteni.</p>
      <p>Samal tasemel disaineri palkamine maksaks teie ettevõttele umbes 72 000€ - 120 000€ aastas + töövahendid jm personali kulud. Lisaks peate arvestama kaasneva bürokraatiaga.</p>
      <p>Meie teenust saate kasutada vastavalt vajadusele (juhul kui on vaba kohta). Nii hoiate kulud kontrolli all ja saate kasutada kvaliteetset disaini teenust just siis kui teil on seda vaja.</p>
      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Mida te piiramatu disaini teenuse puhul ei paku?</h3>
      <div className="answer">
      <p>Antud disaini teenuse raames me ei saa pakkuda unikaalseid illustratsioone, 3D graafikat, videotöötlust või animeerimist, tekstide kirjutamist ja sisuloomet.</p>
      <p>Kui teil on kahtlus kas saame teile vajalikku disainiteenust pakkuda võtke ühendust ja rääkige julgelt oma soovidest ning vajadustest.</p>
      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Me soovime töödata mitme projektiga&nbsp;korraga?</h3>
      <div className="answer">
      <p>Sellisel juhul piiramatu disainiteenuse pakett teile ei sobi. Jätkame ka klassikalise töömudeliga kus töötame kas kokkulepitud projektipõhise või päeva hinnaga. Kui usute, et saame teid aidata siis räägime.
</p>
<p>Piiramatu disaini paketti tellijale kes soovib kiiret lisatööd pakkume püsikliendi hinnda. Planeeritavate projektide järjekorda saate alati ise kontrollida.</p>

      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Miks te minu piiramatu disaini teenuse päringule ei ole vastanud?</h3>
      <div className="answer">
      <p>Me vastame kindlasti igale disainiteenuse päringule. Enamasti vähemalt 48 tunni jooksul kui mitte kiiremini. Vaata spämmi kausta ja kui sealt ka meie vastust ei leia siis ole hea ning saada lihtsalt uus kodulehekülje päring. Või helista.</p>
      <p>Kui saatsid meile miski "külma" müügipakkumise siis vabandust aga jätame õiguse mitte vastata</p>
     
      </div>
    </li>
  </ul>
</div>

    </div>

      </section>
      </GiveContentTrans>



      <GiveContentTrans language="en">
        <section className="description-section inner-grid">

          <div className="section-title">
          <h3>Frequently Asked Questions</h3>
<h2>Regarding Monthly Design Package</h2>
          </div>

        <div className="content-row has-media">
        <div id="faq">
  
  <ul>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>How quickly do you send the designs?</h3>

      <div className="answer">
      <p>For many briefs, we send the designs back within 1-2 business days, and sometimes even faster.</p>
      <p>Complex projects may take longer, and we break them down into phases. For instance, when designing a website with 5 subpages, it's not practical to design all 5 subpage views simultaneously in various screen sizes.
      Or, if you order animated banners in 10 different sizes from us, we will initially create a sample in one or two sizes. And after master banner is approved we will prepare whole package of banners.      </p>
      <p>The more detailed the initial brief is, the faster we can provide solutions.</p>




      </div>
    </li>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Can I really submit unlimited design requests?</h3>
<div className="answer">
    <p>Yes, we do not limit the number of projects you can request. Our goal is to provide a comprehensive solution for small businesses and assist larger companies in a specific segment.</p>
    <p>We handle projects in a queue. To start the next project, the previous one must be approved. You can provide unlimited feedback for each project, ensuring that you are satisfied with the final result.</p>
    <p>We also offer a fixed monthly fee (€3507+VAT) for Framer/Webflow website creation, management, and comprehensive graphic and UI/UX design services. You can order unlimited design work from us (e.g., landing pages, website updates, promotional materials, etc.) and no-code software development. Most tasks, except for extensive projects, are completed within 48 business hours. By hiring a UI/UX designer and web developer at the same level, you can save over 50% on labor costs annually, potentially saving tens of thousands of euros.</p>

</div>

    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>How does this service help my company save money and time?</h3>
<div className="answer">
    <p>Traditionally when working with a digital agency, everything starts with project pricing. Once the price is agreed upon, the digital agency schedules the work. The coordination of the initial brief, the preparation and review of quotations, and then contract signing all take a lot of time. With the unlimited design work model, you simply send your requests, and the designer starts working.</p>
    <p>When working with a digital agency on an hourly pricing basis, the agency often aims to make the work appear more complex and bill as many hours as possible. At times, it goes against logic that quick and high-quality work is rewarded with lower pay. The flat-rate unlimited design service solves this problem. You have a need for quality design, and Give aims to provide it quickly and at a transparent price.</p>
</div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>What happens if I'm not satisfied with the design?</h3>
<div className="answer">
    <p>If you are not satisfied with the initial design provided, you can always provide feedback and describe in detail what you would like to improve.</p>
    <p>We will work on the project until you are satisfied, and the created design aligns with your business goals. For full transparency you must understand that the sooner you approve a project the faster we can start with the next one. </p>
</div>

    </li>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Can I get a refund if I'm not satisfied with the design service?</h3>
<div className="answer">
    <p>No, we do not offer refunds. If you are not satisfied with the service, you can cancel the service at any time.</p>
    <p>If, for some reason, we are unable to provide the unlimited design service, we will refund you for the period in question.</p>
</div>

    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Unlimited design requests is not possible? What's the catch?</h3>
<div className="answer">
    <p>True. It seems too good to be true. But it's not.</p>
    <p>You can submit unlimited requests, and we work on them in sequence. No designer can handle 10 projects simultaneously. To start the next design project, the previous one must be approved.The ball is in your court.</p>
    <p>The clearer the initial briefs and the faster you provide feedback on your requirements, the more designs we can create.</p>
    <p>Unlimited design service is entirely focused on and optimized for speed. It doesn't work for all clients, but for those for whom this work model fits, it delivers incredible results. We don't do meetings, video calls, or live chats. Unless you really really want that.  Detailed request > Specific design response. It's a simple, fast, growth-focused solution. We have been using this work model for graphic design and web design for years, and it just works.</p>
    <p>Unlimited flat-rate design service is possible due to the use of experienced designers. We don't plan, do background research, design wireframes, or journey maps as separate stage. We start designing right away, and thanks to experience, we usually get it 90% right. The more precise and detailed the initial brief and the explanation of needs and problems, the better the results.</p>
    <p>With the unlimited monthly design service, you can also order larger projects such as <ListTLink
              to="/services/websites/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >website designs
            </ListTLink>, SaaS desktop <ListTLink
              to="/services/ui-ux-design/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >UI/UX design
            </ListTLink>, <ListTLink
              to="/services/mobile-apps/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >mobile app design
            </ListTLink>, in addition to landing pages and UI/UX updates. We divide these projects into reasonable phases. For example, let's take a 7-subpage website. In the first phase, we establish the homepage design, which sets the overall visual style. Once that is approved, we move on to designing, for instance, 2 core subpages. After these are approved by the client, we design the remaining 4 subpages of the website. After all subpages are approved, we design the necessary mobile views and hand the project over to the developer. With this phased approach, we can ensure that we are always moving in the right direction, and clients have a precise overview of what's happening.</p>
</div>

    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Why shouldn't I hire a full-time designer?</h3>
<div className="answer">
    <p>We offer a broader range of services than the average designer can or wants to provide. We do everything from corporate branding to banners to more complex <ListTLink
              to="/services/ui-ux-design/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >UI/UX solutions
            </ListTLink>
            .</p>
    <p>Hiring a designer at the same level full-time would cost your company approximately €62,000 - €120,000 per year, plus expenses and other personnel costs. Additionally, you'd need to consider the associated bureaucracy.</p>
    <p>You can use our service as needed (provided there is availability). This allows you to keep costs under control and utilize a high-quality design service precisely when you need it.</p>
</div>

    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

<h3>What do you not offer with the unlimited design service?</h3>
<div className="answer">
    <p>Within this design service, we cannot provide unique illustrations, 3D graphics, video editing or animation, text and content creation.</p>
    <p>If you have any doubts about whether we can provide the design service you need, please contact us and feel free to discuss your requirements and needs.</p>
</div>

    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>We want to work on multiple projects simultaneously. Can you accommodate that?</h3>
<div className="answer">
    <p>In that case, the unlimited design service package may not be suitable for you. At Give Digital Agency, we continue to work with the traditional model where we work on either a project-based or daily rate basis, as agreed. If you believe we can assist you, let's talk.</p>
    <p>If you are a subscriber to the unlimited design package and want additional work done quickly, we can offer it at a fixed client rate. You can always adjust the order of planned projects according to your company's priorities.</p>
    <p>You may want to read about some of our most popular services: <ListTLink
              to="/services/websites/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            ><h4>Website design</h4></ListTLink> <ListTLink
              to="/services/ui-ux-design/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
                     <h4>UI/UX design</h4>
            </ListTLink>        <ListTLink
              to="/services/brand-identity/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <h4>Logos&corporate identity design</h4>
            </ListTLink></p>
</div>

    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Why haven't you responded to my unlimited design service request?</h3>
<div className="answer">
    <p>We definitely respond to every design service request, typically within 48 hours or even faster. Please check your spam folder, and if you still can't find our response there, please feel free to submit a new website request. Alternatively, you can give us a call.</p>
    <p>If you sent us a "cold" sales offer, we apologize, but we reserve the right not to respond.</p>
</div>

    </li>
  </ul>
</div>

    </div>

      </section>
      </GiveContentTrans>


      <section className="description-section cta-section inner-grid">
        <div className="page-copy cta-block">
          <GiveContentTrans language="et">
            <h2>Kiirus, kvaliteet ja soodne hind&nbsp;kombineeritud</h2>
    
            <p>
            Kui te ei ole veel veendunud, et kuumaksupõhine disainipakett on teile sobivaim lahendus, siis selgitame koos välja, kas see võiks teie ettevõttele sobida. 
            </p>

            <div className="button-row">
          <a href="https://cal.com/tanels/design-subscription" className="heading4">
            <MagneticButton
              addionalClasses="cta cta-xl"
              buttonLabel="Broneeri TASUTA videokõne"
              magneticDistance="150"
              magneticSpeed="0.4"
            />
          </a>
         <p>Saad esimese disaini juba 48h pärast ↓</p>
          <a href="mailto:tanel@give.ee?subject=Kuumaksup%C3%B5hise%20disainipaketti%20tellimus&body=Ettev%C3%B5te%20v%C3%B5i%20eraisike%20kes%20soovib%20tellida%3F%20%0AKas%20soovite%20Pro%20v%C3%B5i%20Preemium%20paketti%3F%0AK%C3%BCsimusi%3F">Telli disainipakett</a>
        </div>

          </GiveContentTrans>
          <GiveContentTrans language="en">
          <h2>Combining Speed, Quality, and Affordable Pricing</h2>

<p>Still not convinced that the monthly subscription design package is a great deal? We'd be happy to discuss the pros and cons of this service in more detail and determine together whether it could benefit you.</p>

<div className="button-row">
  <a href="https://cal.com/tanels/design-subscription" className="heading4">
    <MagneticButton
      addionalClasses="cta cta-xl"
      buttonLabel="Book a FREE video call"
      magneticDistance="150"
      magneticSpeed="0.4"
    />
  </a>
  <p>Receive design work in just 48 hours ↓</p>
  <a href="mailto:tanel@give.ee?subject=Subscription%20to%20the%20Design%20Package&body=Is%20this%20for%20a%20company%20or%20individual%20who%20wishes%20to%20subscribe%3F%20%0APro%20or%20Premium%20package%3F%0AAny%20questions%3F">Order a design package</a>
</div>


          </GiveContentTrans>
        </div>
      </section>




    </main>
    </Layout>
); }


export default PiiramatuDisainPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;